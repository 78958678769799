<template>
  <div class="list-box">
    <div class="list-box__header">
      <i
        v-if="icon"
        class="header__icon"
        :class="`icon-${icon}`"
      />
      {{ title }}
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'

export default {
  name: 'ListBox',
  props: {
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    }
  },
  slots: Object as SlotsType<{
    default: {}
  }>
}
</script>

<style lang="scss" scoped>
.list-box {
  border: 1px solid $border-ternary;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden auto;

  &__header {
    min-height: 48px;
    display: flex;
    align-items: center;
    column-gap: $margin-tiny;
    @include font-text($font-weight-medium);
    @include font-size($font-size-small);
    text-transform: uppercase;
    color: $color-palette-3;
    padding: 0 $margin-intermediate;
    background-color: $bg-quinary;
    border-bottom: 1px solid $border-ternary;
    box-sizing: border-box;
    position: sticky;
    top: 0;

    .header__icon {
      @include font-icon;
      @include font-size(18px);
      color: $color-palette-3;
    }
  }
}
</style>
